import { navigate } from "gatsby";
import React from "react";

import trophyIcon from "../../images/trophy.svg";
import Button from "../../DesignSystem/Button";

import * as S from "./style";
import StyledLink from "../../DesignSystem/StyledLink";

export default function PlanoCard({
  titulo,
  preco,
  recomendado,
  children,
  taxdashs,
}) {
  return (
    <S.Card
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeOut", duration: 0.8 }}
    >
      <S.Title $recomendado={recomendado} $business={titulo.toLowerCase() === "business"} $premium={titulo.toLowerCase() === "premium"}>
        <h3>{titulo}</h3>
        {recomendado && (
          <S.Recomendado>
            <img src={trophyIcon} alt="" />
          </S.Recomendado>
        )}
      </S.Title>
      <S.Features>{children}</S.Features>
        {/* <S.Price>
          <div>
            <p>A partir de</p>
            <span>R$</span>
          </div>
          <span>{preco}</span>
          <p>/ mês (cobrança anual)</p>
        </S.Price>
        <S.ObsPrice>
          Preços para Consultorias, Auditorias, Escritórios de Advocacia e
          Escritórios Contábeis. Para conhecer os pacotes para departamentos
          fiscais de corporações{" "}
          <StyledLink to="/contato#form-contato">entre em contato</StyledLink>
        </S.ObsPrice> */}
      <S.CTA $taxdashs={true}>
        {taxdashs ? (
          <>
            <Button
              onClick={() => {
                if (typeof window !== "undefined")
                  window.location.href = `https://azuremarketplace.microsoft.com/pt-br/marketplace/apps/taxcel.taxdashs?tab=overview`;
              }}
              mb={24}
              flat
              fluid
              color="secondary"
            >
              Teste gratuitamente
            </Button>
            <Button
              onClick={() => {
                // navigate("/contato", {
                //   state: { msg: "demonstracao", produto: "taxdashs" },
                // });
                navigate("/demonstracao#taxdashs");
              }}
              mb={24}
              flat
              fluid
              bg="verde"
              color="primary"
            >
              Assistir demonstração
            </Button>
            <Button
              mb={24}
              flat
              fluid
              color="primary"
              ghost
              onClick={() => {
                navigate("/contato#form-contato", {
                  state: { msg: "proposta", produto: "taxdashs" },
                });
              }}
            >
              Solicitar proposta
            </Button>
          </>
        ) : (
          <>
            {" "}
            <Button
              onClick={() => navigate("/baixar-taxsheets")}
              mb={24}
              flat
              fluid
              color="secondary"
            >
              Teste 7 dias grátis
            </Button>
            <Button
              onClick={() => {
                // navigate("/comprar", { state: { basic: !recomendado } });
                navigate("/demonstracao#taxsheets");
              }}
              mb={24}
              flat
              fluid
              bg="verde"
              color="primary"
            >
              Assistir demonstração
            </Button>
            <Button
              mb={24}
              flat
              fluid
              color="primary"
              ghost
              onClick={() => {
                navigate("/contato#form-contato", {
                  state: { msg: "proposta", produto: "taxsheets" },
                });
              }}
            >
              Solicitar proposta
            </Button>
          </>
        )}
      </S.CTA>
    </S.Card>
  );
}
